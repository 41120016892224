body.light {
  background-color: #E9ECEF;

  .notification-container > .popover {
    border-radius: 1em !important;
  }

  .notification-toast {
    max-width: max-content !important;
  }
}
