@import "~bootstrap/dist/css/bootstrap.css";

/* overrides modal adding padding */
body {
    padding-right: 0 !important;
    padding-top: 5.2rem;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* ios padding */
    @supports (padding-top: max(0px)) {
        padding-top: calc(5.2rem + env(safe-area-inset-top));
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a.text-success:hover {
    color: #28a745 !important;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: .6em;
    background-color: inherit;
}
  
*::-webkit-scrollbar {
    width: .6em;
    height: .6em;
    background-color: inherit;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 1px 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 1px 0 6px rgba(0,0,0,0.3);
    background-color: inherit;
}
